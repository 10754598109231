.Empty {
  width: 550px;
  height: 353px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.05);
  margin: 137px auto;
  border-radius: 0.75rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 34.375rem;
  padding: 2.5rem;
  img {
    width: 100px;
  }
  h1 {
    font-size: 24px;
    margin: 32px 0 8px;
  }
  p {
    font-size: 16px;
    margin: 0 0 40px;
  }
  button {
    font-size: 14px;
    padding: 12px 24px;
    background-color: #51267d;
    color: #fff;
    border-radius: 1.25rem;
    border: none;
  }
}
.Container {
  display: flex;
  justify-content: space-between;
}
