.CurrierCall {
  padding: 1.5rem 1.25rem;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.05);
  border-radius: 1rem;
  .Heading {
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 140%;
    color: var(--darkgray-2);
    margin-bottom: 1rem;
  }
  .Radio {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    cursor: pointer;
    vertical-align: middle;
    -webkit-tap-highlight-color: transparent;
    label {
      display: inline-flex;
      -webkit-box-align: center;
      align-items: center;
      cursor: pointer;
      vertical-align: middle;
      -webkit-tap-highlight-color: transparent;
      gap: 5px;
      margin-right: 8px;
      span {
        display: inline-flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        position: relative;
        box-sizing: border-box;
        -webkit-tap-highlight-color: transparent;
        background-color: transparent;
        outline: 0px;
        border: 0px;
        margin: 0px;
        cursor: pointer;
        user-select: none;
        vertical-align: middle;
        text-decoration: none;
        padding: 9px;
        border-radius: 50%;
        color: rgba(0, 0, 0, 0.6);
        input {
          cursor: inherit;
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0px;
          left: 0px;
          margin: 0px;
          padding: 0px;
          z-index: 1;
        }
      }
    }
  }
}
