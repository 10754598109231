.Header {
    width: 1200px;
    height: 75px;
    display: flex;
    margin: 15px auto;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 1.5rem;
    margin-left: 0;
    .Heading {
      width: 112.47px;
      height: 39px;
      font-size: 2rem;
      line-height: 2.4375rem;
    }
    .Links {
      width: 300px;
      height: 44px;
      display: flex;
      align-items: center;
      grid-gap: 0.75rem;
      gap: 0.75rem;
      z-index: 10;
      justify-content: space-between;
      a {
        width: 144px;
        height: 44px;
        border-radius: 1.375rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        &.CurrentPage1{
          background-color: #51267d;
          color: #fff;
        }
        &.CurrentPage2{
          background-color: #f1eff4;
          color: #48535b;
        }
      }
    }
  }