.PersonalInfo {
  padding: 24px 20px;
  margin-bottom: 24px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  .Heading {
    font-weight: 600;
    font-size: 20px;
    line-height: 140%;
    color: var(--darkgray-2);
    margin-bottom: 16px;
  }
  .Bottom {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0.75rem;
    gap: 0.75rem;
    width: 100%;
    .Info {
      width: 100%;
      h3 {
        font-size: 16px;
      }
      input {
        width: 100%;
        border: 1px solid #e5e9eb;
        padding: 0.625rem 1rem;
        border-radius: 0.75rem;
        // -moz-appearance: none;
        // appearance: none;
        // -webkit-appearance: none;
        transition: 0.15s;
        &:focus {
          outline: none;
        }
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
    }
    .Number {
      position: relative;
      &::before {
        content: "+998";
        position: absolute;
        z-index: 999;
        top: 50%;
        transform: translateY(5%) translateX(20px);
        color: #000;
      }
        input{
          padding-left: 60px;
        }
    }
  }
}