.About_img{
    margin-top: 20px;margin-bottom: 20px;border-radius: .75rem;width: 100%;
}
.p{
    font-size: 1rem;
    font-weight: 300;
    margin-top: 2rem;
    max-width: 51.125rem;
    // line-height: 1.5rem;
    color: #252c32;
    font-family: Inter,sans-serif;
}
h1{
    margin-top: 20px;
}