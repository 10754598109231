.Cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0;
  .Card {
    width: 304px;
    height: 378px;
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    box-shadow: 0 0 10px 1px #dedede;
    margin-top: 25px;
    img {
      width: 100%;
      height: 200px;
      margin-top: 13px;
    }
    .Prop {
      width: 85%;
      margin: auto;
      .Title {
        margin-top: 10px;
        font-size: 20px;
      }
      .Desc {
        font-size: medium;
      }
      .Bottom {
        display: flex;
        justify-content: space-between;
        margin-top: 0;
        align-items: center;
        .Price {
          font-size: 20px;
          margin-top: 0;
          span {
            font-weight: normal;
          }
        }
        .Add {
          background-color: #51267d;
          border: none;
          color: #fff;
          font-size: 0.825rem;
          border-radius: 1.25rem;
          width: 105px;
          height: 40px;
          margin-bottom: 10px;
        }
        .ItemQuan {
          display: flex;
          align-items: center;
          background-color: #fff;
          border: 1px solid #51267d;
          width: 105px;
          height: 40px;
          border-radius: 1.25rem;
          overflow: hidden;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          justify-content: space-between;
          padding: 0 20px;
          align-items: center;
          margin-bottom: 10px;
          button {
            background-color: transparent;
            border: none;
            font-size: 16px;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            width: 1em;
            height: 1em;
            display: inline-block;
            fill: currentColor;
            -webkit-flex-shrink: 0;
            -ms-flex-negative: 0;
            flex-shrink: 0;
            -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            font-size: 1.5rem;
            margin-bottom: 10px;
          }
          .ItemQuantity {
            background-color: transparent;
            font-weight: 600;
            padding-bottom: 8px;
            font-size: 18px;
          }
        }
      }
    }
  }
}
.DescUndefined {
  width: 100%;
  height: 30px;
}
